

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Heading extends Vue {
  @Prop({ required: true })
  private title: string;

  @Prop() private description: string;
}
