

























import { Component, Vue, Prop } from 'vue-property-decorator';
import Heading from '@/components/shared/Heading.vue';

@Component({
  components: {
    Heading,
  },
})
export default class ContentWrapper extends Vue {
  @Prop({ required: true })
  private title: string;

  @Prop() private description: string;
}
