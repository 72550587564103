




import { Component, Vue } from 'vue-property-decorator';
import GuideContent from '@/components/guide/GuideContent.vue';

@Component({
  components: {
    GuideContent,
  },
})
export default class Guide extends Vue {}
