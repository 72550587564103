


















import { Component, Vue } from 'vue-property-decorator';
import PageContentWrapper from '@/components/shared/PageContentWrapper.vue';
// Issues with webpack loading Markdown files means this doesn't work
// import * as userGuide from "@/data/SnapShot-UserGuide.md";

@Component({
  components: {
    PageContentWrapper,
  },
})
export default class GuideContent extends Vue {
  private guide;

  constructor() {
    super();
    this.guide = '';
  }
}
